
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { IUserProfileCreate } from "@/interfaces";
import { dispatchCreateUser } from "@/store/admin/actions";

@Component
export default class Signup extends Vue {
  public valid = false;
  public appName = appName;
  public nickname: string = "";
  public email: string = "";
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";
  public termsAccepted = false;

  public async submit() {
    if (await this.$validator.validateAll()) {
      const userProfile: IUserProfileCreate = {
        email: this.email,
        nickname: this.nickname,
      };
      userProfile.password = this.password1;
      const error = await dispatchCreateUser(this.$store, userProfile);
      if (!error) {
        this.$router.push("/profile");
      }
    }
  }
}
